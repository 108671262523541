import React,{useEffect, useState} from 'react'
import {Routes, Route} from 'react-router-dom'
import LoanForm from '../page/LoanForm'
// import Welcome from '../page/Welcome'
// import CaptureId from '../page/Facematching/CaptureId'
// import CaptureSelfie from '../page/Facematching/CaptureSelfie'
// import Facematching from '../page/Facematching/index'
// import SignUp from '../page/SignUp'


function AllRoutes() {

  return (
    <>
        <Routes>
        <Route exact path="/" element={<LoanForm/>} />
        {/* <Route exact path="/form" element={<LoanForm />} />
        <Route exact path="/id" element={<CaptureId />} />
        <Route exact path="/selfie" element={<CaptureSelfie />} />
        <Route exact path="/kyc" element={<Facematching />} />
        <Route exact path="/signup" element={<SignUp />} /> */}
        
        </Routes>
    </>
  )
}

export default AllRoutes