import React, { useEffect, useRef, useState } from 'react'
import autoAnimate from '@formkit/auto-animate'
import { useMono } from 'react-mono-js';
import CurrencyInput from 'react-currency-input-field';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import nigeriaStates from '../states.json'
function LoanForm() {
  const formRef = useRef();
  const [termsChecked, setTermsChecked] = useState(false)
  const [selectedState, setSelectedState] = useState(0)
  const [selectedLGA, setSelectedLGA] = useState("")
  const [repaymentAmount, setRepaymentAmount] = useState(0) 
  const [repaymentLarger, setRepaymentLarger] = useState(false)

  const handleMono = useMono({ public_key: process.env.REACT_APP_MONO_KEY });
  const [resultMessage, setResultMessage] = useState('')
  const [resultStatus, setResultStatus] = useState(false)
  const [page, setPage] = useState(1)
  const [showForm, setShowForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [monoLoading, setMonoLoading] = useState(false)
  const [unlockPrice, setUnlockPrice] = useState(null)
  const [upfrontPrice, setUpfrontPrice] = useState(null)
  const [nominalTerm, setNominalTerm] = useState(null)
  const [minimumPaymentAmount, setMinimumPaymentAmount] = useState(null)
  const [resultMsg, setResultMsg] = useState("")
  
  const defaultData = {
    "monoCode": null,
    "gender": "",
    "dateOfBirth": null,
    // "age": null,
    "lgaOfResidence": "",
    "businessType": "",
    "productGroupName": "",
    "unlockPrice": 0,
    "upfrontPrice": 0,
    "customerOccupation": "",
    "customerStateOfResidence": "",
    "nominalTerm": 0,
    "minimumPaymentAmount": 0
  }

  const defaultDataString = {
    "monoCode": "Mono code",
    "gender": "Gender",
    "dateOfBirth": "Date of birth",
    "lgaOfResidence": "LGA of residence",
    "businessType": "Business type",
    "productGroupName": "Product group name",
    "unlockPrice": "Unlock price",
    "upfrontPrice": "Upfront price",
    "customerOccupation": "Customer occupation",
    "customerStateOfResidence": "Customer state of residence",
    "nominalTerm": "Nominal term",
    "minimumPaymentAmount": "Minimum payment amount"
  }
  
  const [loanFormData, setLoanFormData] = useState(defaultData)

  const reset = () => {
    setLoanFormData(defaultData)

    setPage(1)
    setShowForm(false)
  }


  const Msg = ({ failedCheck }) => (
    <div>
      The following fields are empty:
      {failedCheck.map((field)=>{
        return(
          <p><b>{ defaultDataString[field] }</b></p>
        )
      })}
    </div>
  )

  const submitForm = () => {

    let toCheck = ["monoCode", "gender", "dateOfBirth", "lgaOfResidence", "businessType", "productGroupName", "unlockPrice", "upfrontPrice", "customerOccupation", "customerStateOfResidence", "nominalTerm", "minimumPaymentAmount"]

    let checked = true
    let failedCheck = []
    console.log(loanFormData)
    toCheck.forEach((thisKey, index) => {

      // "monoCode": null,
      // "gender": "",
      // "dateOfBirth": null,
      // "age": null,
      // "lgaOfResidence": "",
      // "businessType": "",
      // "productGroupName": null,
      // "unlockPrice": null,
      // "upfrontPrice": null,
      // "customerOccupation": "",
      // "customerStateOfResidence": "",
      // "nominalTerm": null,
      // "minimumPaymentAmount": null
      
      checked = (loanFormData[thisKey] !== defaultData[thisKey]) && checked
      if(loanFormData[thisKey] === defaultData[thisKey]){
        failedCheck.push(thisKey)
      }
    })

    if (checked && !loading) {
      setLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("x-api-key", process.env.REACT_APP_API_KEY);
      var raw = JSON.stringify(loanFormData);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_API_URL}api/decision/koolboks`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          setPage(3)

          if(typeof result.succeded !== 'undefined'){
            if(typeof result.data !== 'undefined'){
              setResultMsg(result.data.message)
              if(typeof result.data.overallDecision !== 'undefined'){
                setResultMessage(result.data.overallDecision === "Accept" ? 'Loan request accepted' : result.data.overallDecision)
                setResultStatus(result.data.overallDecision === "Accept" ? true : false)
                
              }else{
                setResultMessage('Loan request rejected')
                setResultStatus(false)
              }
            }else{
              setResultMessage('Loan request rejected')
              setResultStatus(false)
            }
          }else{
            setResultMessage('Loan request rejected')
            setResultStatus(false)
          }
          
          setLoading(false)
        })
        .catch(error => {
          console.log('error', error)
          setResultMessage("Error occurred while doing decisioning, Check your internet connection. If Error persist contact system administrator")
          setResultStatus(false)
          setLoading(false)
        });
    }else if(!checked){
      toast.info(<Msg failedCheck={failedCheck} />)
      // let failedCheckString = failedCheck.join("/") 
      // toast.info(`The values [${failedCheckString}] are empty.`)
    }
  }

  useEffect(() => {
    if (formRef.current) {
      autoAnimate(formRef.current);
    }
  }, [formRef]);

  useEffect(()=>{
    reset()
  },[])
  useEffect(()=>{
    if(resultMessage !== ""){
      toast(resultMessage)
    }
  },[resultMessage])
  const proceedToMono = () => {
    setMonoLoading(true)
    handleMono({
      onClose: () => {

      },
      onSuccess: (response) => {


        setLoanFormData({ ...loanFormData, monoCode: response.code })
        console.log(response.code)
        if (typeof response.code !== 'undefined' && response.code !== null) {
          setTimeout(() => {
            setPage(2)
            setShowForm(true)
            setMonoLoading(false)
          }, 7000)

        }
      },
    })
  }

  return (
    <div className='main'>
      <ToastContainer />
      <div className='logo'></div>
      <div className='loan_widget'>
        {page === 1 &&
          <div>
            <h2>Welcome to Koolbox BNPL</h2>

            {/* <div className='d-flex checkbox_wrapper'>
                  <input type="checkbox" name="banking-details" />
                  <div className='checkbox_text'>Online / Mobile Banking Details</div>
                </div>
                <br/>
                <div className='d-flex checkbox_wrapper'>
                  <input type="checkbox" name="id-card" />
                  <div className='checkbox_text'>Valid ID Card (Passport/ Driver's Licence / NIN)</div>
                </div> */}
            <br />
            <div className='d-flex checkbox_wrapper'>
              <input type="checkbox" name="id-card" className='checkbox' checked={termsChecked}
                onChange={(e) => {
                  setTermsChecked(!termsChecked)
                }}
              />
              <div className='checkbox_text'>By checking this, You acknowledge and accept the terms and conditions.</div>
            </div>
            <br />
            {termsChecked &&
              <div
                onClick={() => {
                  setPage(2)
                  proceedToMono()
                }}
                className='submit'>Continue
              </div>
            }
          </div>
        }

        {typeof loanFormData !== 'undefined' && !showForm && page === 2 && !monoLoading &&
          <div>
            <div className='checkbox_text text-center'>You are required to submit your bank statement.</div>
            <div className='submit' onClick={() => proceedToMono()}>
              Click to submit bank statement</div>
          </div>
        }

        {monoLoading && !showForm &&
          <div>
            <div className={`icon loading`}></div>
            <div className='checkbox_text text-center'>...Getting bank statement.</div>
          </div>
        }

        {typeof loanFormData !== 'undefined' && showForm && page === 2 &&
          <div>
            <h2 >Customer Information</h2>
            <div className='label'>
              Customer Gender
              <div className={`icon ${loanFormData.gender !== "" ? 'ok' : 'not_ok'}`} ></div>
            </div>
            <select id="gender" name="gender"
              defaultValue={loanFormData.gender}
              onChange={(e) => {
                let gender = e.target.value;
                setLoanFormData({ ...loanFormData, gender: gender })
              }}
            >
              <option value="" disabled >Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>

            <div className='label'>
              Customer date of birth
              <div className={`icon ${loanFormData.dateOfBirth !== null ? 'ok' : 'not_ok'}`} ></div>
            </div>
            <input type="date" id="dob" name="dob"
              onChange={(e) => {
                let value = e.target.value;
                setLoanFormData({ ...loanFormData, dateOfBirth: value + 'T08:27:04.562Z' })
              }}
            />
            {/* <div className='label'>
              Customer Age
              <div className={`icon ${loanFormData.age !== null ? 'ok' : 'not_ok'}`} ></div>
            </div>
            <input type="number" id="age" name="age"
              onChange={(e) => {
                let value = e.target.value;
                setLoanFormData({ ...loanFormData, age: value })
              }}
            /> */}
            {/* unlockPrice */}
            {/* "nominalTerm": 3, */}
            {/* "minimumPaymentAmount": 21111 */}
            <div className='label'>
              Unlock Price
              <div className={`icon ${loanFormData.unlockPrice !== null ? 'ok' : 'not_ok'}`} ></div>
            </div>

            <CurrencyInput
              id="unlockPrice" name="unlockPrice"
              placeholder="Please enter a number"
              prefix="₦"
              defaultValue={unlockPrice === null ? 0 : unlockPrice}
              // decimalsLimit={2}
              onValueChange={(value, name) => {
                let numberRegex = /^\d+$/;
                if (numberRegex.test(value)) {
                  setUnlockPrice(value)
                  setLoanFormData({ ...loanFormData, unlockPrice: value })
                }
              }}
            />

            <div className='label'>
              Minimum Payment Amount
              <div className={`icon ${loanFormData.minimumPaymentAmount !== null ? 'ok' : 'not_ok'}`} ></div>
            </div>
            {repaymentLarger &&
            <div className="warning">Minimum payment amount cannot be larger than unlock price.</div>
            }
            <CurrencyInput
              id="minimumPaymentAmount"
              name="minimumPaymentAmount"
              placeholder="Please enter a number"
              prefix="₦"
              defaultValue={minimumPaymentAmount === null ? 0 : minimumPaymentAmount}
              value={minimumPaymentAmount}
              onValueChange={(value, name) => {
                  // let numberRegex = /^\d+$/;
                  // if (numberRegex.test(value)) {
                  if(typeof value === "undefined"){
                    setMinimumPaymentAmount(10)
                    setLoanFormData({ ...loanFormData, minimumPaymentAmount: 1 })
                    setRepaymentLarger(false)
                  }else if(Number(loanFormData.unlockPrice) > Number(value)){
                    setMinimumPaymentAmount(value)
                    setLoanFormData({ ...loanFormData, minimumPaymentAmount: Number(value) })
                    setRepaymentLarger(false)
                  }else{
                    setRepaymentLarger(true)
                  }
                }
              }
            />

            <div className='label'>
              Upfront Price
              <div className={`icon ${loanFormData.upfrontPrice !== null ? 'ok' : 'not_ok'}`} ></div>
            </div>

            <CurrencyInput
              id="upfrontPrice" name="upfrontPrice"
              placeholder="Please enter a number"
              prefix="₦"
              defaultValue={upfrontPrice === null ? 0 : upfrontPrice}
              // decimalsLimit={2}
              onValueChange={(value, name) => {
                let numberRegex = /^\d+$/;
                if (numberRegex.test(value)) {
                  setUpfrontPrice(value)
                  setLoanFormData({ ...loanFormData, upfrontPrice: value })
                }
              }}
            />

            <div className='label'>
              Nominal Term (Duration in months)
              <div className={`icon ${loanFormData.nominalTerm !== null ? 'ok' : 'not_ok'}`} ></div>
            </div>
            <input type="number" id="nominalTerm" name="nominalTerm" value={nominalTerm === null ? '' : nominalTerm}
              onChange={(e) => {
                let numberRegex = /^\d+$/;
                if (numberRegex.test(e.target.value)) {
                  setNominalTerm(e.target.value)
                  setLoanFormData({ ...loanFormData, nominalTerm: Number(e.target.value) })
                }
              }}
            />


            


            <div className='label'>
              Customer state of residence
              <div className={`icon ${loanFormData.customerStateOfResidence !== null ? 'ok' : 'not_ok'}`} ></div>
            </div>
            {/* <input type="text" id="state" name="state"
              onChange={(e) => {
                let value = e.target.value;
                setLoanFormData({ ...loanFormData, customerStateOfResidence: value })
              }}

            /> */}

            <select
            onChange={
              (e)=>{
                setSelectedState(e.target.value)
                setLoanFormData({ ...loanFormData, customerStateOfResidence: nigeriaStates[e.target.value].state })
              }
            }
            >
              <option value="" disabled>Select a state</option>
              {nigeriaStates.map((thisState, index)=>{
                return(
                <option value={index} key={index}>{thisState.state}</option>
              )})}
            </select>
            <div className='label'>
              Customer LGA
              <div className={`icon ${loanFormData.lgaOfResidence !== null ? 'ok' : 'not_ok'}`} ></div>
            </div>
            {/* <input type="text" id="lga" name="lga"
              onChange={(e) => {
                let value = e.target.value;
                setLoanFormData({ ...loanFormData, lgaOfResidence: value })
              }}
              x /> */}

            {typeof nigeriaStates[selectedState] !== 'undefined' &&
            <select
            onChange={
              (e)=>{
                setLoanFormData({ ...loanFormData, lgaOfResidence: e.target.value })
              }
            }
            >
              <option value="" disabled>Select an LGA</option>
              {nigeriaStates[selectedState].lgas.map((lga, index)=>{
                return(
                <option value={lga} key={index}>{lga}</option>
              )})}
            </select>
            }


            <div className='label'>
              Business Type
              <div className={`icon ${loanFormData.businessType !== null && loanFormData.businessType !== "" ? 'ok' : 'not_ok'}`} ></div>
            </div>
            <select id="business" name="business"
              xdefaultValue={loanFormData.businessType}
              onChange={(e) => {
                let value = e.target.value;
                setLoanFormData({ ...loanFormData, businessType: value })
              }}
            >
              <option value="" disabled selected>Select Business Type</option>
              <option value="beverages_dealer">Beverages Dealer</option>
              <option value="civil_servant">Civil Servant</option>
              <option value="restaurants_bar">Restaurants/Bar</option>
              <option value="frozen_food_seller">Frozen Food Seller</option>
              <option value="ice_block_seller">Ice Block Seller</option>
              <option value="corporate_worker">Corporate Worker</option>
              <option value="others">Others</option>
            </select>


            <div className='label'>
              Customer Occupation
              <div className={`icon ${loanFormData.customerOccupation !== null && loanFormData.customerOccupation !== "" ? 'ok' : 'not_ok'}`} ></div>
            </div>
            <select id="occupation" name="occupation"
              defaultValue={loanFormData.customerOccupation}
              onChange={(e) => {
                let value = e.target.value;
                setLoanFormData({ ...loanFormData, customerOccupation: value })
              }}
            >
              <option value="" disabled>Select Customer Occupation</option>
              <option value="merchant">Merchant (Trade)</option>
              <option value="cook">Cook (Food Service)</option>
              <option value="electricity_gas">Electricity, Gas</option>
              <option value="health_social_work">Health and Social Work</option>
              <option value="administration">Administration</option>
              <option value="manufacturing">Manufacturing</option>
              <option value="hotel">Hotel (Accommodation)</option>
              <option value="water_supply_cleaning">Water Supply, Cleaning</option>
              <option value="teacher">Teacher (Education)</option>
              <option value="driver">Driver (Transportation)</option>
              <option value="artist">Artist, Entertainment</option>
              <option value="farmer_crops">Farmer (Crops)</option>
              <option value="farmer_animals">Farmer (Animals)</option>
              <option value="construction">Construction</option>
              <option value="textile_production">Textile Production</option>
              <option value="security_guard">Security, Guard</option>
              <option value="vehicle_repair">Repair of Motor Vehicles</option>
              <option value="furniture_production">Furniture Production</option>
              <option value="others">Others</option>
            </select>


            <div className='label'>
              Product Group Name
              <div className={`icon ${loanFormData.productGroupName !== null && loanFormData.productGroupName !== "" ? 'ok' : 'not_ok'}`} ></div>
            </div>
            <input type="text" id="product_group" name="product_group"
              onChange={(e) => {
                let value = e.target.value;
                setLoanFormData({ ...loanFormData, productGroupName: value })
              }}
            />

            <br /><br />

            <div className='submit' onClick={() => submitForm()}>
              {loading && <div className='loading'></div>}
              {loading ? 'Getting Loan Decision' : 'Generate Decision'}
            </div>
          </div>
        }

        {page === 3 && showForm &&
          <div>
            <div className={`icon ${resultStatus ? 'success' : 'failed'}`}></div>
            <div className='result_message'>{resultMessage}</div>
            <div className='result_msg'>{resultMsg}</div>
            <div className='submit' onClick={() => reset()}>
              Start Another</div>
          </div>
        }
        <div className='fine_notes'>Powered by Izifin</div>
      </div>

    </div>
  )
}

export default LoanForm